<template>
    <div class="flex flex-row items-start gap-1 py-2 md:gap-3">
        <div class="hidden pl-4 md:block">
            <button
                v-if="planPageLink !== null"
                type="button"
                @click="toContractBooking"
            >
                <prime-avatar
                    :icon="icon"
                    size="large"
                    shape="circle"
                    class="bg-gray-200/80 text-gray-800"
                />
            </button>
            <template v-else>
                <prime-avatar
                    :icon="icon"
                    size="large"
                    shape="circle"
                    class="bg-gray-200/80 text-gray-800"
                />
            </template>
        </div>
        <div class="pl-4 sm:pr-1 md:hidden md:pr-0">
            <button
                v-if="planPageLink !== null"
                type="button"
                @click="toContractBooking"
            >
                <prime-avatar
                    :icon="icon"
                    size="normal"
                    shape="circle"
                    class="bg-gray-200/80 text-gray-800"
                />
            </button>
            <template v-else>
                <prime-avatar
                    :icon="icon"
                    size="normal"
                    shape="circle"
                    class="bg-gray-200/80 text-gray-800"
                />
            </template>
        </div>

        <div class="flex-auto">
            <div class="flex items-center justify-between">
                <nuxt-link
                    v-if="planPageLink !== null"
                    :to="planPageLink"
                    @click="setCartVisible(false)"
                >
                    <prime-button
                        link
                        class="!p-0 !text-left"
                        :label="title"
                    />
                </nuxt-link>
                <div
                    v-else
                    class="text-900 font-bold"
                >
                    {{ title }}
                </div>
                <div class="flex flex-col items-end">
                    <div
                        v-if="nonDiscountedSubtotalFormatted !== ''"
                        class="line-through"
                    >
                        {{ nonDiscountedSubtotalFormatted }}
                    </div>
                    <div class="text-900 font-bold">{{ totalFormatted }}</div>
                </div>
            </div>

            <template v-if="isGiftCard">
                <div class="text-gray-600">
                    {{ contractData.terms.giftCardTerms.firstName }}
                    {{ contractData.terms.giftCardTerms.lastName }}
                </div>

                <div class="text-sm text-gray-600">
                    {{ contractData.terms.giftCardTerms.email }}
                </div>
            </template>

            <div
                v-else
                class="whitespace-pre text-sm text-gray-600"
            >
                {{ contractDatesDisplay }}
            </div>

            <div
                v-if="contractData.promotions.length > 0"
                class="mt-1 flex flex-wrap gap-2"
            >
                <div
                    v-for="promotion in contractData.promotions"
                    class="flex items-center gap-2 text-green-600"
                >
                    <i class="pi pi-tag" />
                    <div>{{ promotion.code }}</div>
                </div>
            </div>

            <div class="flex flex-auto items-start justify-end gap-3">
                <div
                    v-if="contractData.enrollmentSpecifications.length > 0"
                    class="mt-2 flex-1 text-sm text-gray-600"
                >
                    Participants
                    <ul class="list-disc pl-4 md:pl-6">
                        <template
                            v-for="(
                                specification, index
                            ) in contractData.enrollmentSpecifications"
                            :key="index"
                        >
                            <template v-if="specification.count > 0">
                                <template
                                    v-if="specification.enrollments.length"
                                >
                                    <li
                                        v-for="(
                                            enrollment, enrollmentIndex
                                        ) in specification.enrollments"
                                        :key="enrollmentIndex"
                                    >
                                        {{
                                            enrollment.customer.commaName
                                        }}&nbsp;/&nbsp;{{
                                            enrollment.enrollmentType.singular
                                        }}
                                    </li>
                                </template>
                                <li
                                    v-else
                                    class="italic"
                                >
                                    {{ specification.count }}
                                    {{ specification.enrollmentType.singular }}
                                </li>
                            </template>
                        </template>
                    </ul>

                    <div
                        v-if="contractIncompleteMessage !== ''"
                        class="text-red-600 mt-2 text-sm font-semibold"
                    >
                        {{ contractIncompleteMessage }}
                    </div>
                </div>

                <div class="self-end text-right">
                    <div
                        v-if="
                            contractIncompleteMessage === '' && expirationPast
                        "
                        class="text-red-600 font-semibold"
                    >
                        {{ expirationRelativeString }}
                    </div>

                    <div
                        v-if="!isKiosk"
                        class="mt-2 flex items-center justify-end gap-3"
                    >
                        <prime-button
                            label="Edit"
                            icon="pi pi-pencil"
                            size="small"
                            text
                            @click="handleEditContract"
                        />

                        <delete-contract-button
                            :contract-id="contractData.id"
                            @deleted="handleContractDeleted"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { graphql } from '~/resources/graphql'

export const PlanContractCartItemFragment = graphql(/* GraphQL */ `
    fragment PlanContractCartItemFragment on PlanContract {
        id
        planType
        draftExpiresAtUtc
        properties {
            id
            value
            originalQuestionText
        }
        plan {
            name
            slug
        }
        planType
        startDateLocal
        startSession {
            startDateLocal
        }
        course {
            isComposite
            publicTitle
        }
        endDateLocal
        enrollmentSpecifications {
            enrollmentType {
                singular
            }
            count
            enrollments {
                customer {
                    id
                    commaName
                }
                enrollmentType {
                    singular
                }
            }
        }
        readyForPurchase(input: { checkDocument: true })
        terms {
            giftCardTerms {
                firstName
                lastName
                email
                message
            }
        }
        promotions {
            code
        }
    }
`)
</script>

<script setup lang="ts">
import { Decimal } from '~/composables/support/decimal'
import {
    formatRange,
    formatTimelessRange,
    parseLocal,
    parseUtc
} from '~/composables/support/localization'
import PrimeButton from 'primevue/button'
import PrimeAvatar from 'primevue/avatar'
import DeleteContractButton from '~/components/CartSidebar/DeleteContractButton.vue'
import { useToast } from 'primevue/usetoast'
import { useRouter } from 'vue-router'
import { setCartVisible } from '~/composables/use-cart'
import type { FragmentType } from '~/resources/graphql'
import { getFragmentData } from '~/resources/graphql'
import { computed, inject, type Ref } from 'vue'
import { useActiveFacility } from '~/composables/use-active-facility'
import { useRouteLink } from '~/composables/routing'
import { useContractExpiration } from '~/components/PurchaseContract/use-contract-expiration'
import { useLayoutMode } from '~/composables/use-layout-mode'
import NuxtLink from '#app/components/nuxt-link'

const props = defineProps<{
    contract: FragmentType<typeof PlanContractCartItemFragment>
    total: Decimal
    nonDiscountedSubtotal: Decimal
}>()

const contractData = computed(() =>
    getFragmentData(PlanContractCartItemFragment, props.contract)
)

const { isKiosk } = useLayoutMode()
const isGiftCard = computed(() => contractData.value.planType === 'GIFTCARD')

const icon = computed(() => {
    if (contractData.value.planType === 'PASS') {
        return 'pi pi-ticket'
    }

    if (contractData.value.planType === 'PROGRAM') {
        return 'pi pi-calendar'
    }

    if (isGiftCard.value) {
        return 'pi pi-gift'
    }

    return 'pi pi-users'
})

const title = computed(() => {
    if (
        contractData.value.planType === 'PROGRAM' &&
        contractData.value.course !== null
    ) {
        return contractData.value.course.publicTitle
    }

    return contractData.value.plan.name
})

const contractDatesDisplay = computed(() => {
    if (
        contractData.value.planType === 'MEMBERSHIP' ||
        contractData.value.planType === 'PASS'
    ) {
        return formatTimelessRange(
            parseLocal(contractData.value.startDateLocal),
            parseLocal(contractData.value.endDateLocal)
        )
    }

    if (contractData.value.planType === 'PROGRAM') {
        return formatRange(
            parseLocal(contractData.value.startDateLocal),
            parseLocal(contractData.value.endDateLocal)
        )
    }

    throw new Error('Unknown plan type')
})

const totalFormatted = computed(() => props.total.formatCurrency())
const nonDiscountedSubtotalFormatted = computed(() =>
    props.nonDiscountedSubtotal.isNotEqualTo(props.total)
        ? props.nonDiscountedSubtotal.formatCurrency()
        : ''
)

const expirationDate = computed(() =>
    contractData.value.draftExpiresAtUtc !== null
        ? parseUtc(contractData.value.draftExpiresAtUtc)
        : null
)
const { expirationRelativeString, expirationPast } =
    useContractExpiration(expirationDate)

const toast = useToast()
function handleContractDeleted() {
    toast.add({
        severity: 'error',
        summary: 'Deleted',
        detail: 'Item removed',
        life: 3000,
        closable: false
    })
}

const router = useRouter()
const activeFacility = useActiveFacility()
const { facilityEditContractLink, facilityCreateContractLink } = useRouteLink()
function handleEditContract() {
    router.push(
        facilityEditContractLink(
            activeFacility.value.slug,
            contractData.value.planType,
            contractData.value.id
        )
    )
    setCartVisible(false)
}

const storefrontPrefix = inject<Ref<string>>('STOREFRONT_PREFIX')
const planPageLink = computed(() => {
    if (storefrontPrefix === undefined) {
        return null
    }

    return facilityCreateContractLink(
        storefrontPrefix.value,
        contractData.value.planType,
        contractData.value.plan.slug
    )
})

function toContractBooking() {
    if (planPageLink.value === null) {
        return
    }

    router.push(planPageLink.value)
    setCartVisible(false)
}

const contractIncompleteMessage = computed(() => {
    if (contractData.value.readyForPurchase === 'VALID') {
        return ''
    }

    // if (import.meta.env.DEV) {
    //     return `Contract Incomplete - Edit to finish (${contractData.value.readyForPurchase})`
    // }

    return 'Contract Incomplete - Edit to finish'
})
</script>
